import React from 'react';
export const Nav20DataSource = {
  isScrollLink: true,
  wrapper: { className: 'header2 home-page-wrapper l31a6nzhqj8-editor_css' },
  page: { className: 'home-page l32h7ubmxrb-editor_css' },
  logo: {
    className: 'header2-logo',
    children:
      'https://github.com/shuinye/network/blob/main/Creative%20Tycoon_600-240.png?raw=true',
  },
  LinkMenu: {
    className: 'header2-menu',
    children: [
      {
        name: 'linkNav',
        to: 'Banner5_0',
        children: 'About',
        className: 'menu-item l32gtzq409t-editor_css',
      },
      {
        name: 'linkNav~l31j8glxsm',
        to: 'Content1_0',
        children: 'Service',
        className: 'menu-item l32gx2gunlk-editor_css',
      },
    ],
  },
  mobileMenu: { className: 'header2-mobile-menu' },
};
export const Banner00DataSource = {
  wrapper: { className: 'banner0 l31a731c7pt-editor_css' },
  textWrapper: { className: 'banner0-text-wrapper l31am21zjqn-editor_css' },
  title: {
    className: 'banner0-title l31a70444uo-editor_css',
    children:
      'https://github.com/shuinye/network/blob/main/Creative%20Tycoon_7-4.png?raw=true',
  },
  content: {
    className: 'banner0-content l31a810ch09-editor_css',
    children: (
      <span>
        <p>Global Performance Network</p>
      </span>
    ),
  },
  button: {
    className: 'banner0-button l31a936h5vo-editor_css',
    children: (
      <span>
        <span>
          <span>
            <p>Contact us：account@creativetycoon.comus</p>
          </span>
        </span>
      </span>
    ),
  },
};
export const Banner50DataSource = {
  wrapper: { className: 'home-page-wrapper banner5 l31aektixsa-editor_css' },
  page: { className: 'home-page banner5-page l31abuncxis-editor_css' },
  childWrapper: {
    className: 'banner5-title-wrapper l31abip204-editor_css',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>Who we are</p>
          </span>
        ),
        className: 'banner5-title l31achqv95e-editor_css',
      },
      {
        name: 'content',
        className: 'banner5-content l31acyk9d9s-editor_css',
        children: (
          <span>
            <span>
              <p>
                We’re passionate about helping you to reach your target
                audience, get them on board, engage with them and finally retain
                them for higher LTV.
              </p>
            </span>
          </span>
        ),
      },
    ],
  },
  image: {
    className: 'banner5-image l31aax06s0p-editor_css',
    children:
      'https://github.com/shuinye/network/blob/main/WHO%20WE%20ARE.png?raw=true',
  },
};
export const Content10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children:
      'https://github.com/shuinye/network/blob/main/Pay%20only%20for%20results.png?raw=true',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title l31airzz9q9-editor_css',
    children: (
      <span>
        <p>Pay only for results</p>
      </span>
    ),
  },
  content: {
    className: 'content1-content l31ajrxnu1t-editor_css',
    children: (
      <span>
        <p>
          Pay only if unique users install or perform specific actions with your
          app. Creative Tycoon Mobile Performance Agency gives you access to
          more than thousands of active users at the ad platform. Flexible on
          CPI/CPR/CPA/CPS business models.
        </p>
      </span>
    ),
  },
};
export const Feature20DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children:
      'https://github.com/shuinye/network/blob/main/Get%20only%20quality%20traffic.png?raw=true',
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: {
    className: 'content2-title l31aiw7fe29-editor_css',
    children: (
      <span>
        <p>Get only quality traffic</p>
      </span>
    ),
  },
  content: {
    className: 'content2-content l31ajhho5jn-editor_css',
    children: (
      <span>
        <span>
          <span>
            <p>
              Our optimization system constantly monitors all traffic patterns
              to prevent fraud and abuse. We have established strong
              relationship with the best traffic sources, allowing our
              advertisers to find the most accurate users all over the world. On
              a constant base we review activity metrics of our traffic to make
              sure your mobile app is offered only to high quality users and
              where you will see maximum results.
            </p>
          </span>
        </span>
      </span>
    ),
  },
};
export const Feature10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children:
      'https://github.com/shuinye/network/blob/main/1-to-1%20Account%20Manager.png?raw=true',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title l31aiyy6klo-editor_css',
    children: (
      <span>
        <p>1-to-1 Account Manager</p>
      </span>
    ),
  },
  content: {
    className: 'content1-content l31aj1mrqvl-editor_css',
    children: (
      <span>
        <p>
          Increase your ROI by working with the highly motivated personal
          account manager. We will take care about your campaigns 24/7. Any
          question you may have, you can contact us any time. User base growth
          usually is the top priority for the brand. But if you’re new to the
          performance marketing media scene, it also can be quite difficult so
          we are here to help and acquire the right audience in the most
          efficient way.
        </p>
      </span>
    ),
  },
};
export const Footer00DataSource = {
  wrapper: {
    className: 'home-page-wrapper footer0-wrapper l31jai1rwzs-editor_css',
  },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        <p>
          <span>©2022 Creative Tycoon All Rights Reserved</span>
        </p>
      </span>
    ),
  },
};
